import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const SectionTitle = ({title}) => (
  <div className="col-12 col-sm-4 text-center text-sm-end mb-3 borderRight">
    <h2 className="prices-section-title">{title}</h2>
  </div>
);

const SectionPricesLi = ({name, price}) => (
  <li>
    <div className="row">
        <div className="col-8">
          {name.startsWith('-') ?
          <h5 className="item-title-attention">{name.substring(1)}</h5> :
          <h5 className="item-title">{name}</h5> }
          {/*{name.startsWith('-') && <p className="item-desc">{name}</p>}*/}
        </div>
        <div className="col-4 price">{price}</div>
      </div>
    <hr className="soften"/>
  </li>
)

const Prices = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allPrice1809Csv {
        edges {
          node {
            price
            name
            titleId
          }
        }
      }
      allTitles1809Csv {
        nodes {
          titleId
          title
        }
      }
    }
  `)

  const prices = data.allPrice1809Csv.edges.map((item) => item.node)
  const titles = data.allTitles1809Csv.nodes

  return (
    <section className="prices my-5 py-5">
      <div className="container">
        {titles.map((title) => (
          <div className="row mb-5" key={title.titleId}>
            <SectionTitle title={title.title}/>
            <div className="col-12 col-sm-8">
              <ul>
                <li>
                  <div className="row">
                    <div className="col-8">
                      <h5 className="item-title prices-title">Послуга</h5>
                    </div>
                    <div className="col-4 price price-title">грн.</div>
                  </div>
                  <hr className="soften"/>
                </li>
                {prices.map((price) => {
                  if(price.titleId === title.titleId) {
                   return (<SectionPricesLi key={price.name} name={price.name} price={price.price} /> )
                  }
                  return null
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Prices;