import React from 'react';
import Seo from "../components/seo";
import Layout from "../components/layout";
import ServicesInfo from "../components/Services/ServicesInfo";
import Prices from "../components/Services/Prices";

const Services = ()=> (
  <Layout>
    <Seo title="Послуги"/>
    <ServicesInfo/>
    <Prices/>
  </Layout>
);

export default Services;